header .language-prefix__control {
    border: none;
    background-color: inherit;
}

header .language-prefix__control:hover {
    border: none;
}

.language-prefix__indicator-separator {
    display: none;
}

.language-prefix__menu,
.language-prefix__single-value {
    color: #212426;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.3px;
}

.language-prefix__indicator svg {
    fill: #000;
}

.listing-Carousel {
    width: 79.083px;
}

.listing-Carousel .slick-track .slick-slide img {
    /* width: 79.083px; */
    height: 75.92px;
    border-radius: 16px;
    cursor: pointer;
    border: 1px solid rgba(33, 38, 35, 0.10);
}

.listing-Carousel .slick-track {
    display: flex;
    flex-direction: column;
    height: auto !important;
}

.listing-Carousel .slick-track::before {
    display: none;
}


.listing-Carousel .slick-track .slick-slide .list-image {
    height: 92px;
}


.listing-Carousel .slick-list {
    height: 613px;
}

.expandedView-Carousel {
    width: 521px;
    height: 613px;
}

.expandedView-Carousel .main-image {
    /* position: relative; */
}

.expandedView-Carousel .share-Icon {
    width: 40px;
    height: 40px;
    background: #FFF;
    filter: drop-shadow(0px 6.588235378265381px 31.05882453918457px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1.8823529481887817px 18.823530197143555px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1.8823529481887817px 3.7647058963775635px rgba(0, 0, 0, 0.08));
    position: absolute;
    top: 24px;
    right: 24px;
    border-radius: 50%;
    padding: 8;
}

.expandedView-Carousel .slick-arrow {
    top: 50%;
    width: 40px;
    filter: drop-shadow(0px 6.588235378265381px 31.05882453918457px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1.8823529481887817px 18.823530197143555px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1.8823529481887817px 3.7647058963775635px rgba(0, 0, 0, 0.08));
    height: 40px;
    display: flex !important;
    z-index: 1;
    background: #fff;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
}

.expandedView-Carousel .slick-arrow::before {
    display: none;
}

.expandedView-Carousel .slick-arrow svg {
    color: #1F55DE;
}

.expandedView-Carousel .slick-arrow.slick-prev {
    left: 16px;
}

.expandedView-Carousel .slick-arrow.slick-next {
    right: 16px;
}

.slider-slide {
    width: 100%;
    height: 200px; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }

/* Target screens with a width between 768px and 1023px */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    /* CSS rules specific to tablets */
}


@media screen and (max-width: 767px) {
    .listing-Carousel .slick-list {
        height: auto;
    }

    .listing-Carousel .slick-track {
        display: flex;
        flex-direction: row;
        height: auto !important;
    }

    .listing-Carousel {
        width: auto;
    }
    .expandedView-Carousel {
        width: 321px;
        height: 413px;
    }
    /* .expandedView-Carousel .slick-arrow {
        display: none !important;
    } */


}