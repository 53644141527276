/* Style the scrollbar for Chrome, Safari, and newer Edge */
/* Note: Webkit browsers include Chrome, Safari, and newer Edge */
::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #7A7A7A;
    border-radius: 6px;
  }
  
  /* Style the scrollbar for Firefox */
  /* Note: Firefox does not support styling the scrollbar via pseudo-elements */
  /* You may need to use a custom scrollbar library for Firefox */
  /* or accept the default scrollbar styling in Firefox */
  
  /* Style the scrollbar for Microsoft Edge (legacy) */
  /* Note: Older versions of Edge (legacy) use -ms- prefix */
  *::-ms-scrollbar {
    width: 8px;
  }
  
  *::-ms-scrollbar-thumb {
    background-color: #7A7A7A;
    border-radius: 6px;
  }
  