/* Default styles */
.rbc-calendar {
    height: 100% !important;
    font-family: 'Inter';
}

.rbc-month-view {
    border-radius: 8px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
    background-color: #FDE189;
    color: rgba(33, 36, 38, 0.75);
    cursor: pointer;
}

.rbc-row-segment {
    padding: 0 12px 4px 12px;
}

.rbc-show-more {
    color: #FFF !important;
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 68px;
    background: #FE7F2D !important;
    width: 100% !important;

}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: #FDE189 !important;
    outline: #FDE189 !important;
}
.rbc-event:focus {
    background-color: #FDE189 !important;
    outline: #FDE189 !important;
}

.rbc-today {
    background-color: transparent;
}

/* Media queries for responsive font sizes */
@media screen and (max-width: 767px) {
    .rbc-show-more {
        font-size: 12px; /* Adjust font size for mobile screens */
    }
    .rbc-row-segment {
        padding: 0;
        font-size: 12px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .rbc-show-more {
        font-size: 13px; /* Adjust font size for tablets */
    }
}

/* For laptops and above, the font size will remain as defined in the default styles */
