/* Customize the dashed line */
.custom-step {
    position: relative;
  }
  
  .custom-step:not(:first-of-type)::before {
    content: '';
    position: absolute;
    top: -8px; /* Adjust this value to align the dashed line */
    left: 0;
    right: 0;
    border-top: 2px dashed gray; /* Change the color and style as needed */
    z-index: -1;
  }
  
  /* Adjust the position of navigation buttons */
  .step-navigation {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    margin-top: 20px;
  }
  