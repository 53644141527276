.m-0 {
    margin: 0 !important;
}

p.email-verified img {
    vertical-align: middle;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-wrapper span.calendar-icon {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    color: rgb(33 36 38 / 90%);
}

.react-datepicker__month-container {
    font-family: 'Inter', sans-serif;
}

/* =============== */
.mobileNumberWrapper .country-select-col {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.react-datepicker__year-option:first-child.react-datepicker__year-option--selected_year {
    margin-top: 30px;
    opacity: 1;
}

.react-datepicker__year-option:first-child {
    opacity: 0;
}

.react-datepicker__year-dropdown:before {
    content: '▲';
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}

.react-datepicker__year-dropdown:after {
    content: '▼';
    position: absolute;
    cursor: pointer;
    top: auto;
    bottom: 6px;
    right: 53px;
}

.mobileNumberWrapper .react-custom-flag-select__select__buttonWrapper {
    width: 130px !important;
}

input#mobile_number {
    padding: 15px 0px 15px 40%;
}

.mobileNumberWrapper .react-custom-flag-select__select__selector {
    height: 100%;
    padding: 0 3px 0 10px;
    display: flex;
    align-items: center;
    width: 100%;
}

.mobileNumberWrapper .country-select-col .country-flag img {
    border-radius: 3px;
}

.upload-wrapper label span {
    font-weight: 400;
}

.react-datepicker__input-container {
    border: 1px solid #C3CAD9;
    padding: 15px 20px;
    border-radius: 7px;
    color: rgb(33 36 38 / 90%);
    font-size: 16px;
    font-weight: 500;
    display: block;
    width: 100%;
    font-family: 'Inter', sans-serif;
    line-height: 150%;
}

.react-datepicker-wrapper .date-placeholder,
.dropdown-menu-list {
    color: rgb(33 36 38 / 50%);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.MuiMenu-paper .countryCode-list-item li {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.date-value,
.date-placeholder {
    display: flex;
    align-items: center;
    height: 24px;
    justify-content: space-between;
    cursor: pointer;
}

.error-block-otp span.required-field {
    color: #F00;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
}

span.red-field {
    color: #FF0000;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid rgb(33, 36, 38, 90%);
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: rgb(33, 36, 38, 90%);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.verify-email-modal .resend-btn-enable {
    color: #233D4D;
    cursor: pointer;
    white-space: break-spaces
}

.verify-email-modal .resend-btn-disable {
    color: rgba(33, 36, 38, 40%);
    cursor: not-allowed;
    white-space: break-spaces;
}

.main-wrapper {
    margin-top: 81px;
    margin-bottom: 148px;
}

.congratulations-modal {
    width: 100%;
    max-width: 465px;
    padding: 32px 40px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 1;
    border-radius: 12px;
    box-shadow: 0 0 2px rgb(0 0 0 / 12%), 0 20px 20px rgb(0 0 0 / 8%);
    text-align: center;
}

.congratulations-modal h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 8px 0;
    color: rgb(33 36 38 / 90%);
}

.congratulations-modal span.close-btn {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}

.verify-email-modal {
    width: 100%;
    max-width: 465px;
    padding: 40px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 1;
    border-radius: 12px;
    box-shadow: 0 0 2px rgb(0 0 0 / 12%), 0 20px 20px rgb(0 0 0 / 8%);
    text-align: center;
}

.verify-email-modal h4 {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 8px;
    color: rgb(33 36 38 / 80%);
}

.verify-email-modal p {
    color: rgb(33 36 38 / 50%);
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 24px;
}

.verify-email-modal span.close-btn {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
}

.verify-email-modal .opt-timer-block {
    color: black;
    display: flex;
    justify-content: space-between;
    width: 63%;
    gap: 20px;
    margin: 15px auto 0;
    padding-bottom: 40px;
}

.otp-time {
    color: #233D4D;
}

.calendar.visible {
    top: 97.5% !important;
    left: 29.5% !important;
}

.country-down-arrow {
    height: 20px;
    width: 100%;
    max-width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.divider-input {
    width: 0.5px;
    height: 24px;
    background: rgba(33, 36, 38, 0.20);
}

.country-code {
    margin-right: 12px;
}

.country-flag {
    margin-right: 8px;
}

.customer-signup-container {
    width: 77%;
    margin: 0 auto;
    padding: 0 8px
}

.MuiBox-root.gender-radio-list {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-top: 24px;
    flex-wrap: wrap;
}

.MuiFormGroup-root.radio-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 32px;
}

.guest-form .flexGap8 {
    display: flex;
    gap: 8px;
}

.guest-form .flexGap24 {
    display: flex;
    gap: 24px;
}

.guest-form .profile-image .default,
.guest-form .profile-image {
    height: 120px;
    width: 120px;
}

.flag-image {
    width: 35.766px;
    height: 24px;
    border-radius: 4px;
}

.guest-form .mt-16 {
    margin-top: 24px;
}

.guest-form .mtb-root32 {
    margin: 32px 0
}

.guest-form .mt-root47 {
    margin-top: 47px
}

.guest-form .mt24 {
    margin-top: 24px;
}

@media (max-width : 768px) {
    .react-datepicker__input-container {
        padding: 12.5px 20px;
        font-size: 14px;
    }

    .react-datepicker-wrapper .date-placeholder,
    .inputField .inputGray,
    .inputField .inputBlack,
    .dropdown-menu-list {
        font-size: 14px;
    }

    .MuiBox-root.gender-radio-list {
        gap: 0px;
    }
}

@media (min-width:360px) and (max-width:768px) {
    .customer-signup-container {
        width: 360px;
        padding: 0;
    }

    .verify-email-modal p {
        font-size: 14px;
    }

    .guest-form .profile-image .default,
    .guest-form .profile-image {
        height: 96px;
        width: 96px;
    }

    .guest-form .defaultIcon {
        height: 24.8px;
    }

    .guest-form .mt-16 {
        margin-top: 0;
    }

    .guest-form .mt-root47 {
        margin-top: 40px
    }

    .guest-form .mt24 {
        margin-top: 32px;
    }
}

@media (max-width: 360px) {
    .main-wrapper {
        margin-top: 32px;
        margin-bottom: 96px;
    }

    .verify-email-modal h4 {
        font-size: 22px;
    }

    .guest-form .country-flag {
        min-width: 98px;
        display: flex;
        font-size: 14px;
        align-items: center;
    }

    .flag-image {
        width: 29.803px;
        height: 20px;
        border-radius: 3.333px;
    }

    .verify-email-modal .opt-timer-block {
        width: 88%;
        font-size: 14px;
    }

    .customer-signup-container {
        width: 312px;
        padding: 0;
    }

    .guest-form .flexGap8 {
        gap: 4px
    }

    .guest-form .flexGap24 {
        display: flex;
        gap: 16px;
    }

    .guest-form .profile-image .default,
    .guest-form .profile-image {
        height: 72px;
        width: 72px;
    }

    .guest-form .defaultIcon {
        height: 18.6px;
    }

    .guest-form .mt-16 {
        margin-top: 0;
    }

    .guest-form .mtb-root32 {
        margin: 24px 0
    }

    .guest-form .mt-root47 {
        margin-top: 32px
    }

    .MuiFormGroup-root.radio-list {
        gap: 24px;
    }
}