body {
    font-family: 'Inter', sans-serif;
    margin: 0;
}

/* =========== Theme CSS ============= */

*,
*:after,
*:before {
    box-sizing: border-box;
}

p {
    margin: 0 0 20px;
}

img {
    max-width: 100%;
    height: auto;
}

.button {
    background: #FE7F2D;
    color: #fff;
    border: 1px solid #FE7F2D;
    border-radius: 44px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    box-shadow: none;
    transition: all 0.3s ease-in;
    padding: 15px 20px;
    width: 100%;
    font-family: 'Inter', sans-serif;
}

.login-form-wrapper .button:hover,
.button:hover {
    background: transparent;
    color: #FE7F2D;
    transition: all 0.3s ease-in;
}

span.link {
    color: #FE7F2D;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    line-height: 21px;
}

.forgot-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.m-0 {
    margin: 0 !important;
}

.account-block {
    display: flex;
    justify-content: flex-start;
}

.account-block p {
    margin: 0;
}

.account-block span.link {
    margin-left: 8px;
}

.service-block {
    border-radius: 8px;
    background: #FFF;
    padding: 24px;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
}

.service-block h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: rgb(33 36 38 / 80%);
    line-height: 140%;
}

.service-block p {
    font-size: 14px;
    font-weight: 400;
    color: rgb(33 36 38 / 60%);
    margin: 8px 0 16px;
    line-height: 21px;
}

.service-block span.link {
    font-weight: 600;
    line-height: 21px;
}

.login-image-block img {
    width: 100%;
    max-width: 650px;
    height: 590px;
}

.page-under-review {
    max-width: 554px;
    width: 100%;
}

.login-image-block {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 575px) {
    .page-under-review {
        max-width: 90%;
    }
}

@media screen and (max-width: 1280px) {
    .login-image-block.hidden-sm {
        display: none;
    }
}

.payment-title h2 {
    color: #212426;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    margin: 0 0 16px;
}

.payment-title p {
    color: rgba(33, 36, 38, 0.65);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.6px;
}

.payment-wrapper {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    padding: 43px 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 200px;
}

.payment-block a {
    display: flex;
    padding: 16px 32px;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    background: #FFEBD4;
    margin: 0 0 32px;
    text-decoration: none;
}

.payment-block p {
    margin: 0;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.payment-content {
    padding-top: 28px;
}

.payment-button .button {
    max-width: 255px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.guest-form.login-wrapper {
    padding: 48px 0 48px;
    display: flex;
    flex-grow: 1;
    justify-content: space-between
}

.guest-form .title {
    color: #212426;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-family: 'Inter', sans-serif;
}

.guest-form .rememberMelabel {
    font-size: 14px;
    font-weight: 500;
    color: rgba(33, 36, 38, 0.65);
    font-family: 'Inter', sans-serif;
    letter-spacing: 0;
    line-height: 150%
}

.guest-form .checkbox-root {
    padding: 0;
    width: 16px;
    height: 16px;
    color: #E5EAF4;
    background: #FFF;
    border-radius: 3.2px;
    border: 1px solid #E5EAF4;
    font-family: 'Inter', sans-serif;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16) inset;
}

.guest-form .checkbox-root #rememberMe {
    width: 16px;
    height: 16px;
}

.guest-form .checkbox-root.checked {
    color: #FE7F2D;
}

.guest-form .label {
    color: rgba(33, 36, 38, 0.65);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 10px;
}

.guest-form .inputField {
    padding: 16px 20px 14px;
    border-radius: 8px;
    border: 1px solid;
    background-color: #ffffff;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0;
}

.guest-form .inputField input {
    color: rgba(33, 36, 38, 0.90);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 0;
    height: auto
}

.guest-form .inputField input::placeholder {
    color: rgba(33, 36, 38, 0.50);
}

.guest-form .mb-root56 {
    margin-bottom: 25px;
}

.guest-form .mb-root48 {
    margin-bottom: 48px;
}

.guest-form .mb-root32 {
    margin-bottom: 25px;
}

.guest-form .mb-root24 {
    margin-bottom: 24px;
}

.guest-form .mb-root40 {
    margin-bottom: 20px
}

@media (max-width:768px) {
    .guest-form .inputField {
        padding: 12.5px 20px;
    }

    .guest-form .inputField input::placeholder {
        color: rgba(33, 36, 38, 0.50);
    }
}

@media (min-width:360px) and (max-width:768px) {
    .guest-form .login-form {
        max-width: 360px;
    }

    .guest-form.login-wrapper {
        padding: 96px 0 120px;
    }

    .account-block.mb-root40 {
        margin-bottom: 40px;
    }
}

@media (max-width: 360px) {
    .guest-form.login-wrapper {
        padding: 32px 0 96px;
        display: flex;
        flex-grow: 1;
        justify-content: space-between
    }

    .guest-form .login-form {
        min-width: 312px;
    }

    .guest-form .title {
        font-size: 28px;
    }

    .guest-form .label {
        font-size: 14px;
    }

    .guest-form .inputField input {
        font-size: 14px;
    }

    .guest-form .inputField input::placeholder {
        font-size: 14px;
    }

    .guest-form .mb-root56 {
        margin-bottom: 32px;
    }

    .guest-form .mb-root48 {
        margin-bottom: 32px;
    }

    .guest-form .mb-root32 {
        margin-bottom: 24px;
    }

    .guest-form .mb-root24 {
        margin-bottom: 16px;
    }

    .guest-form .mb-root40 {
        margin-bottom: 32px;
    }

    .service-block {
        padding: 16px;
    }

    .service-block h4 {
        font-size: 18px;
    }

    .service-block p {
        margin-top: 4px;
    }
}