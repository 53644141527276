/* Set a custom height and width for the calendar */
.rmdp-calendar {
    padding: 4px 20px !important;
}


/* Override the selected date color */
.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #FE7F2D !important;
    color: #ffffff !important;
}

/* Change the hover color of dates except selected ones */
.rmdp-day:not(.rmdp-selected):not(.rmdp-disabled):hover span:not(.highlight) {
    background-color: initial !important;
    /* Reset to default background color */
    color: initial !important;
    /* Reset to default text color */
}

.rmdp-header-values {
    color: #828282 !important;
    text-align: center !important;
    font-family: 'Lato', sans-serif !important;
    font-size: 13.911px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;

}

.rmdp-day-picker {
    padding: 5px !important;
    border-top: 1px solid #FE7F2D !important;
}

/* Change the hover color of selected dates */
.rmdp-day.rmdp-selected:not(.rmdp-disabled):hover span:not(.highlight) {
    background-color: #FE7F2D !important;
    /* Set new hover background color for selected dates */
    color: #ffffff !important;
    /* Set new hover text color for selected dates */
}

/* Set the default weekday color to #000 */
.rmdp-week-day {
    color: #000 !important;
    margin: 3px !important;
    font-family: 'Lato', sans-serif !important;
    font-size: 8.347px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 100% !important;
    letter-spacing: 0.25px !important;
    text-transform: uppercase !important;
}

/* Set the color for Saturday (6th day) to #FE7F2D */
.rmdp-week-day:nth-child(1) {
    color: #FE7F2D !important;
    margin: 3px !important;
}

/* Set the color for Sunday (7th day) to #FE7F2D */
.rmdp-week-day:nth-child(7) {
    color: #FE7F2D !important;
    margin: 3px !important;
    margin-right: 4px !important;
}

.rmdp-day span {
    color: #000;
    text-align: center !important;
    font-family: 'Lato', sans-serif !important; 
    font-size: 12.52px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 122.222% !important
}

.rmdp-day.rmdp-deactive,
.rmdp-day.rmdp-disabled {
    color: #000 !important;
    text-align: center !important;
    font-family: 'Lato', sans-serif !important;
    font-size: 12.52px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 122.222% !important;
    opacity: 0.65 !important;
}


/* Remove hover effect on the calendar arrow */
.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.rmdp-month-picker,
.rmdp-year-picker {
    background-color: #FFF6ED !important;
    border-radius: 0 0 5px 5px !important;
    bottom: 2px !important;
    left: 2px !important;
    position: absolute !important;
    right: 2px !important;
    top: 2px !important;
}

/* For screens with a maximum width of 960px */
@media screen and (max-width: 1024px) {
    .rmdp-calendar {
        padding: 2px 5px !important;
    }
    .rmdp-week-day {
        color: #000 !important;
        margin: 1px !important;
        font-family: 'Lato', sans-serif !important;
        font-size: 8.347px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 100% !important;
        letter-spacing: 0.25px !important;
        text-transform: uppercase !important;
    }
    .rmdp-week-day:nth-child(1) {
        color: #FE7F2D !important;
        margin: 1px !important;
    }
    
    /* Set the color for Sunday (7th day) to #FE7F2D */
    .rmdp-week-day:nth-child(7) {
        color: #FE7F2D !important;
        margin: 2px !important;
        margin-right: 4px !important;
    }
  }
  
  /* For screens with a maximum width of 600px */
  @media screen and (max-width: 767px) {
    .rmdp-calendar {
        padding: 2px 22px !important;
    }
    .rmdp-header div {
        padding: 5px 0;
    }
    .rmdp-week-day {
        color: #000 !important;
        margin: 2px !important;
        font-family: 'Lato', sans-serif !important;
        font-size: 8.347px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 100% !important;
        letter-spacing: 0.25px !important;
        text-transform: uppercase !important;
    }
    .rmdp-week-day:nth-child(1) {
        color: #FE7F2D !important;
        margin: 5px !important;
    }
    
    /* Set the color for Sunday (7th day) to #FE7F2D */
    .rmdp-week-day:nth-child(7) {
        color: #FE7F2D !important;
        margin: 5px !important;
        margin-right: 4px !important;
    }
  }